import React, { lazy } from 'react';
import { ProductListPageQuery } from 'api/search';
import { Area, PageType, ProductListResponse, RelativeUrl } from 'generated/data-contracts';
import { messageToApp } from './helpers';

const AccountSelectorPage = lazy(() => import('./routes/AccountSelector'));
const BasketPage = lazy(() => import('./routes/Basket'));
const BrandSelectorPage = lazy(() => import('./routes/BrandSelector'));
const ProfilePage = lazy(() => import('./routes/Profile'));
const SearchApp = lazy(() => import('./routes/SearchApp'));
const CmsPage = lazy(() => import('./routes/Cms'));
const FavouriteListPage = lazy(() => import('./routes/FavouriteList'));
const OrderConfirmationPage = lazy(() => import('./routes/OrderConfirmationPage'));
const NotFoundPage = lazy(() => import('./routes/404'));
const MyInvoicesPage = lazy(() => import('./routes/Invoices'));
const ProductDetailPage = lazy(() => import('./routes/ProductDetail'));
const ProductListPage = lazy(() => import('./routes/ProductList'));
const PreLoginPage = lazy(() => import('./routes/PreLogin'));
const MyOrders = lazy(() => import('./routes/MyOrders'));
const OrderDetailPage = lazy(() => import('./routes/OrderDetail'));

const setStaticPage = (staticPageParams: Partial<RouteParams>): React.ReactElement => {
	let page: React.ReactElement | Promise<void | React.ReactElement>;

	const { pageType, pageData } = staticPageParams;

	switch (pageType) {
		case PageType.AccountSelector: {
			page = <AccountSelectorPage />;
			break;
		}
		case PageType.Basket: {
			page = <BasketPage />;
			break;
		}
		case PageType.BrandSelector: {
			page = <BrandSelectorPage />;
			break;
		}
		case PageType.FavouriteList: {
			page = <FavouriteListPage />;
			break;
		}
		case PageType.Orders: {
			page = <MyOrders />;
			break;
		}
		case PageType.Invoices: {
			page = <MyInvoicesPage />;
			break;
		}
		case PageType.OrderDetail: {
			page = <OrderDetailPage />;
			break;
		}
		case PageType.All:
		case PageType.Search: {
			page = (
				<ProductListPage query={staticPageParams.productListPageQuery} page={pageData as ProductListResponse} />
			);
			break;
		}
		case PageType.Profile: {
			page = <ProfilePage />;
			break;
		}
		case PageType.SearchApp: {
			page = <SearchApp />;
			break;
		}

		case PageType.Checkout: {
			page = <OrderConfirmationPage />;
			break;
		}

		case PageType.PreLogin: {
			page = <PreLoginPage />;
			break;
		}
		case PageType.AppFlowCompleted: {
			messageToApp({ type: 'onboardCompleted' });

			page = <></>;
			break;
		}
		case PageType.AppFlowIncomplete: {
			messageToApp({ type: 'reloadWebView' });

			page = <></>;
			break;
		}
		default: {
			page = <NotFoundPage />;
		}
	}

	return page as React.ReactElement<never, string | React.JSXElementConstructor<unknown>>;
};

interface RouteParams {
	segmentationId: number;
	area: Area;
	pageType?: PageType;
	externalRoute: RelativeUrl;
	productListPageQuery?: ProductListPageQuery;
	productFamilyId?: string;
	pageData?: unknown;
	pageQuery?: string;
	host?: string;
	cookies?: string;
	isServer?: boolean;
	isNotFound: boolean;
	redirect?: (url: string) => void;
}
export const setRoute = (
	routeParams: RouteParams,
): React.ReactElement<never, string | React.JSXElementConstructor<unknown>> => {
	let route: React.ReactElement | Promise<void | React.ReactElement>;
	const {
		segmentationId,
		area,
		pageType,
		productFamilyId = '',
		pageData,
		pageQuery,
		host,
		cookies,
		isServer,
	} = routeParams;

	const {
		productCategoryId,
		phrase,
		page,
		sortBy,
		sortDirection,
		filters,
		minPrice,
		maxPrice,
		pageSize = 60,
	} = routeParams.productListPageQuery || {};
	const productListPageQuery: RouteParams['productListPageQuery'] = {
		productCategoryId: productCategoryId ?? undefined,
		phrase,
		page,
		sortBy,
		sortDirection,
		filters,
		minPrice,
		maxPrice,
		pageSize,
	};

	switch (area) {
		case Area.CMS: {
			route = <CmsPage />;
			break;
		}
		case Area.ProductCategory: {
			route = <ProductListPage query={productListPageQuery} page={pageData as ProductListResponse} />;
			break;
		}
		case Area.Product: {
			route = <ProductDetailPage id={productFamilyId} />;
			break;
		}
		case Area.StaticPages: {
			route = setStaticPage({
				segmentationId,
				pageType,
				productListPageQuery,
				pageData,
				host,
				cookies,
				isServer,
				pageQuery,
			});
			break;
		}
		default: {
			route = <NotFoundPage />;
		}
	}

	return route as React.ReactElement<never, string | React.JSXElementConstructor<unknown>>;
};
