import React, { FC } from 'react';
import { useTranslationQuery } from 'api/translations';
import { Button, Icon } from 'components/shared';
import styles from './CookieSettings.module.scss';

export const CookieSettings: FC = () => {
	const { data: translations } = useTranslationQuery();
	const handleOpen = () => {
		window.showCookieBanner?.();
	};
	return (
		<Button className={styles.button} hasNoStyles onClick={handleOpen}>
			<div className={styles.titleWrapper}>
				<span className={styles.title}>{translations?.settings.cookieSettings}</span>
			</div>

			<Icon className={'u-ml-sm'} name={'chevronDown'} size={'lg'} rotate={270} />
		</Button>
	);
};
