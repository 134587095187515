import React from 'react';
import { useTranslationQuery } from 'api/translations';
import { Button, DrawerHeader, DrawerLevel, Icon } from 'components/shared';
import { CookieSettings } from '../CookieSettings';
import ToggleHideWHSPrice from '../ToggleHideWHSPrice';
import ToggleZoomCorrection from '../ToggleZoomCorrection';
import styles from './SettingsDrawer.module.scss';

interface SettingsDrawerProps {
	handleClose?: () => void;
}

export const SettingsDrawer: React.FunctionComponent<SettingsDrawerProps> = ({ handleClose }) => {
	const { data: translations } = useTranslationQuery();

	const [isOpen, setIsOpen] = React.useState(false);

	const handleBack = () => setIsOpen(false);

	const handleOpen = () => {
		setIsOpen(true);
	};

	return (
		<>
			<Button className={styles.button} hasNoStyles onClick={handleOpen}>
				<div className={styles.titleWrapper}>
					<Icon className={'u-mr-sm'} name={'settings'} size={'md'} />
					<span className={styles.title}>{translations?.settings.title}</span>
				</div>

				<Icon className={'u-ml-sm'} name={'chevronDown'} size={'lg'} rotate={270} />
			</Button>

			{isOpen && (
				<DrawerLevel isActive={isOpen}>
					<DrawerHeader
						handleBack={handleBack}
						handleClose={handleClose}
						title={translations?.settings.title}
					/>
					<section className={styles.settings}>
						<ToggleHideWHSPrice />
						<ToggleZoomCorrection />
						<CookieSettings />
					</section>
				</DrawerLevel>
			)}
		</>
	);
};
