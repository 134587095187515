import React, { FC } from 'react';
import { useTranslationQuery } from 'api/translations';
import { Icon, Link } from 'components/shared';
import styles from './ChangePassword.module.scss';

export const ChangePassword: FC = () => {
	const { data: translations } = useTranslationQuery();
	const currentLocation = window.location.href;
	const changePasswordUrl = `https://passwordreset.microsoftonline.com/?ru=${encodeURIComponent(currentLocation)}`;

	return (
		<Link className={styles.title} linkUrl={changePasswordUrl} hasExternalIconHidden>
			<Icon className={'u-mr-sm'} name={'key'} size={'md'} />
			{translations?.myProfile.changePassword}
		</Link>
	);
};
