import React from 'react';
import { createContext } from 'react';

export type NewTabsContextType = {
	tabList: TabItem[];
	tabRefs: React.MutableRefObject<(HTMLButtonElement | null)[]>;
	selectedTab: TabItem['id'];
	handleKeyPress: (event: React.KeyboardEvent) => void;
	handleSelectTab: (id: TabItem['id']) => void;
	animationTime: number;
	indicatorRef: React.MutableRefObject<HTMLDivElement | null>;
};

type NewTabsProviderProps = {
	tabList: TabItem[];
	initiallySelectedTab?: TabItem['id'];
	children: React.ReactNode;
};

export interface TabItem {
	label: string;
	id: number | string;
}

export const NewTabsContext = createContext<NewTabsContextType | undefined>(undefined);

export const NewTabsProvider = ({ tabList, initiallySelectedTab = tabList[0].id, children }: NewTabsProviderProps) => {
	const [selectedTab, setSelectedTab] = React.useState(initiallySelectedTab);
	const selectedTabIndex = React.useMemo(
		() => tabList.findIndex((tab) => tab.id === selectedTab),
		[selectedTab, tabList],
	);
	const [animationTime, setAnimationTime] = React.useState(0);
	const indicatorRef = React.useRef<HTMLDivElement>(null);
	const ANIMATION_TIME = 500;
	React.useEffect(() => {
		// This is to ensure that the content doesn't fade in on the first render
		setTimeout(() => setAnimationTime(ANIMATION_TIME), ANIMATION_TIME);
	}, []);
	const tabRefs = React.useRef<(HTMLButtonElement | null)[]>([]);

	const handleSelectTab = (id: number | string): void => {
		setSelectedTab(id);
	};

	// Helper to calculate correct next or prev tab
	const handleTabSelection = (firstTabInRound: number, nextTab: number, lastTabInRound: number): void => {
		const tabIndexToSelect = selectedTab === lastTabInRound ? firstTabInRound : nextTab;
		const tabToSelect = tabList[tabIndexToSelect].id;
		handleSelectTab(tabToSelect);
		const tabRef = tabRefs.current[tabToSelect];
		if (tabRef) {
			tabRef.focus();
		}
	};

	// Handle navigation with arrow keys
	const handleKeyPress = (event): void => {
		const tabCount = tabList.length - 1;

		if (event.key === 'ArrowLeft') {
			const last = tabCount;
			const next = selectedTabIndex - 1;
			handleTabSelection(last, next, 0);
		}
		if (event.key === 'ArrowRight') {
			const first = 0;
			const next = selectedTabIndex + 1;
			handleTabSelection(first, next, tabCount);
		}
	};

	React.useEffect(() => {
		const indicator = indicatorRef.current;
		const selectedTabElement = tabRefs.current.at(selectedTabIndex);
		if (indicator && selectedTabElement) {
			indicator.style.width = `${selectedTabElement.offsetWidth}px`;
			indicator.style.left = `calc(${selectedTabElement.offsetLeft}px - var(--tablist-padding)`;
		}
	}, [selectedTabIndex]);

	return (
		<NewTabsContext.Provider
			value={{
				handleKeyPress,
				handleSelectTab,
				animationTime,
				selectedTab,
				tabList,
				tabRefs,
				indicatorRef,
			}}
		>
			{children}
		</NewTabsContext.Provider>
	);
};

export const useNewTabsState = (): NewTabsContextType => {
	const context = React.useContext(NewTabsContext);
	if (!context) {
		throw new Error('useNewTabsState must be used within a NewTabsProvider');
	}
	return context;
};
