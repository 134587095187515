import React from 'react';
import { FetchPreviousPageOptions, UseInfiniteQueryResult } from '@tanstack/react-query';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { Button } from 'components/shared';
import styles from './PaginationPrevious.module.scss';

interface PaginationPreviousProps {
	className?: string;
	fetchPreviousPage?: (options?: FetchPreviousPageOptions) => Promise<UseInfiniteQueryResult>;
}

export const PaginationPrevious: React.FunctionComponent<PaginationPreviousProps> = ({
	className,
	fetchPreviousPage,
}) => {
	const { data: translations } = useTranslationQuery();

	return (
		<div className={classNames(styles.paginationPrevious, className)}>
			<Button variant={'dark'} onClick={() => fetchPreviousPage?.()}>
				{translations?.shared.pagination.showPrevious}
			</Button>
		</div>
	);
};
